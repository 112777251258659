/* Reset Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  height: 100%;
  font-family: var(--font-family-primary, Arial, sans-serif);
  font-size: var(--font-size-base, 16px);
  color: var(--text-color-primary, #333);
}

/* Body Styles */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-primary);
  color: var(--text-color-primary, #333);
}

/* Links */
a {
  text-decoration: none;
}
.height100 {
  height: 100vh;
}
.btnPrimary {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 8px 16px;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.btnPrimary:active,
.btnPrimary:focus,
.btnSecondary:active,
.btnSecondary:focus {
  transform: scale(0.9);
  text-decoration: none;
}
.btnSecondary {
  background-color: var(--secondary-color);
  color: var(--text-color-primary);
  padding: 8px 16px;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.btnPrimary:hover,
.btnSecondary:hover {
  text-decoration: none;
}
.btnDanger {
  background-color: var(--danger);
  color: var(--white);
  padding: 8px 16px;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.textSecondary {
  color: var(--text-color-secondary);
}
.textPrimary {
  color: var(--text-color-primary);
}
.textSuccessDark {
  color: var(--successDark);
}
.textSecondaryLight {
  color: var(--text-color-light);
}
.text-small {
  font-size: 12px;
}
.icon2x {
  font-size: 24px;
}
.linkbtn-block {
  display: block;
}
