@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  /* Primary Colors */
  --primary-color: #0e3964;
  --danger: #bd5656;
  --primary-color-dark: #2e86c1;

  /* Secondary Colors */
  --secondary-color: #ffdd76;
  --successDark: #0c6e43;
  --successLight: #00b929;

  /* Typography */
  --font-family-primary: "Poppins", sans-serif;
  --font-size-base: 16px;
  --font-size-heading: 2rem;
  --font-size-subheading: 1.5rem;
  --font-size-body: 1rem;

  /* Background Colors */
  --background-color-light: #f4f9ff;
  --background-color-gray: #f5f5f5;
  --background-color-dark: #404756;

  /* Text Colors */
  --text-color-primary: #404756;
  --text-color-secondary: #707788;
  --text-color-light: #7c7c7c;
  --white: #fff;
  --black: #000;
}
