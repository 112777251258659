/* Landing Page */
.bgMain {
  background-image: url("../assets/tiger.png");
}
.card-transparancy {
  background-color: rgba(255, 255, 255, 0.8);
}
.btnPadding26 {
  padding: 26px;
}
.headingLight {
  font-size: 40px;
  font-weight: 300;
}
.headingBold {
  font-size: 64px;
  font-weight: 700;
}
.textLight {
  color: var(--text-color-secondary);
  font-size: 12px;
}

/* Date Selection / Gate Selection Page */
.dateBlock,
.availableBlock {
  background-color: var(--primary-color);
  color: var(--white);
  border: 1px solid var(--white);
  transition: all 0.3s ease;
}
.dateBlock h6,
.availableBlock h6 {
  color: var(--white);
}
.dateBlock:focus,
.dateBlock:active,
.dateBlock:hover,
.availableBlock:focus,
.availableBlock:active,
.availableBlock.active,
.availableBlock:hover {
  background-color: var(--secondary-color);
  transform: scale(0.94);
  cursor: pointer;
}

.dateBlock:focus h6,
.dateBlock:active h6,
.dateBlock:hover h6,
.availableBlock:focus h6,
.availableBlock:active h6,
.availableBlock.active h6,
.availableBlock:hover h6 {
  color: var(--primary-color);
}
.holidayBlock:focus h6,
.holidayBlock:active h6,
.holidayBlock:hover h6{
  color: var(--white);
}
.dateBlock.active {
  background-color: var(--secondary-color);
}
.dateBlock.active h6 {
  color: var(--primary-color);
}
.holidayBlock.active h6 {
  color: var(--white);
}
.dateBlock h5 {
  font-weight: 600;
}
/* Gate Selection Block  */
.gateBlock .card {
  border: 1px solid var(--white);
  box-shadow: 1px 2px 4px silver;
  transition: all 0.3s ease;
}
.gateBlock h6 {
  font-weight: 300;
}
.gateBlock h5 {
  font-weight: 600;
}
.available {
  background-color: #d9f3f0;
}
.notAvailable {
  background-color: rgba(189, 86, 86, 0.2);
}
.gateBlock .card:focus,
.gateBlock .card:active,
.gateBlock .card:hover {
  transform: scale(0.94);
  cursor: pointer;
}
.title {
  font-size: 20px;
  font-weight: 600;
}
.stickyTop {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
  padding-bottom: 20px;
  border-bottom: 1px dashed #ddd;
}
/* Validation Page  */
.validationPage h1 {
  font-size: 30px;
  font-weight: 700;
}

/* Mobile Number Component  */
.form-control:focus {
  box-shadow: none;
}

.age {
  width: 110px !important;
}

/* OTP Verification */

.otpBox {
  width: "50px";
  height: "50px";
  font-size: "1.5rem";
  text-align: "center";
  border: "1px solid #ccc";
  border-radius: "5px";
}

/* confirmTickt page */
.vehicleDetails {
  border-bottom: 1px dashed #ddd;
  padding-bottom: 16px;
}
.bookingDetails h2 {
  font-size: 20px;
  font-weight: 700;
  color: var(--text-color-primary);
}
.passengerDetails {
  display: flex;
  margin-top: 16px;
  flex-direction: column;
}
.passengerDetails .passenger {
  padding: 8px;
  border: 1px dashed #ddd;
  border-radius: 6px;
  margin: 6px 0px;
  display: grid;
  align-items: center;
  grid-template-columns: 200px 50px 70px 1fr 1fr;
  gap: 10px;
  width: 100%;
}
.passengerDetails .passenger h5 {
  margin: 0;
}

/* Ticket Booking  */
.payment-summary {
  border: 1px solid #e0e0e0;
  padding: 20px;
  background-color: white;
}

.summary-items {
  margin-bottom: 20px;
}

.item-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  /* border-bottom: 1px dashed #ddd; */
  padding: 8px 0px;
}

.item-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
}

.item-controls {
  display: flex;
  align-items: center;
}

.form-select{
width:166px;
color: light-dark(#333b3c, #efefec);
}
.form-select:focus {
  box-shadow: none;
}

.decrement,
.increment {
  width: 46px;
  height: 46px;
  background-color: #d9534f;
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
.itemQty {
  width: 40px;
  text-align: center;
}
.increment {
  background-color: #5cb85c;
}

.passengers,
.convenience-fee,
.grand-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px dashed #ddd;
}

.grand-total {
  border: 1px dashed var(--text-color-secondary);
  padding: 8px 8px;
}

.proceed-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
}

/* Vehicle Selection Page  */
.totalSelectedSeats {
  padding: 8px;
  border: 1px dashed #ddd;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

/* Driver Seat Map  */
.vehicleSeatMap {
}
.driverSeat {
  width: 100px;
  height: 60px;
  background-color: var(--background-color-dark);
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
}
.seatBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  box-shadow: 1px 2px 4px silver;
  border-radius: 10px;
  margin: 6px;
  cursor: pointer;
}
.seatBlock.seatAvailable {
  background-color: rgba(0, 185, 41, 0.1);
  color: var(--text-color-primary);
}
.seatBlock.seatBooked {
  background-color: var(--successLight);
  color: var(--white);
}
.seatBlock.seatNA {
  background-color: #ddd;
  color: var(--text-color-primary);
}


.process-payment-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}



.form-check-input {
  --bs-switch-width: 50px;
  /* width: 150px;  */
  /* Default is 34px, so increase as needed */
  height: 20px; /* Default is 20px, so increase as needed */
  border-color: #4CAF50;
  /* border-radius: 25px;  */
  /* Make sure the slider stays rounded */
}

.form-check-input:checked::before {
  background-color: #4CAF50; /* Color of the dot when checked */
  color: #4CAF50;
}


.form-check-input:checked {
  background-color: #4CAF50; /* Customize switch color if needed */
}

.form-check-label {
  font-size: 18px; /* Change label font size as well */
}
.form-check-input:focus::before {
  box-shadow: 0 0 0 0.2rem #4CAF50(76, 175, 80, 0.25); /* Adds a focus ring when focused */
}