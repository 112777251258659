.seatOpen {
    background-color: transparent !important;
}
.seatSelected {
    background-color: greenyellow !important;
}
.seatBlocked {
    background-color: gray !important;
}
.seatBooked {
    background-color: chocolate !important;
}
.seatDamaged {
    background-color: gray !important;
}
.seat {
    cursor: pointer;
    height: 50px;
    width: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
    }

    .seat-name {
        font-size: 15px;
        position: absolute;
    }
}
.table {
    display: table;
    border-color: black;
    border-style: solid;
    border-radius: 5pt;
    border-width: 1pt;
    padding: 5pt;
}
.rows {
    display: table-row;
    padding: 5pt;
}
.cell {
    display: table-cell;
    padding: 5pt;
}
